/*eslint-disable*/
import { authGuard } from "@auth0/auth0-vue";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: '/dashboard/tickets',
        name: 'TicketDashboard',
        component: () => import('../components/TicketDashboard.vue'),
        props: true,
        beforeEnter: authGuard,
    },
    {
        path: '/',
        name: 'MetricDashboard',
        component: () => import('../components/MetricDashboard.vue'),
        props: true,
        beforeEnter: authGuard,
    },
    {
        path: '/:companyID/users/employees',
        name: 'EmployeesUsers',
        component: () => import('../components/user/EmployeesUsers.vue'),
        props: true,
        beforeEnter: authGuard,
    },
    {
        path: '/:companyID/users/onboarded',
        name: 'OnboardedUsers',
        component: () => import('./../components/user/OnboardedUsers.vue'),
        props: true,
        beforeEnter: authGuard,
    },
    {
        path: '/:companyID/users/offboarded',
        name: 'OffboardedUsers',
        component: () => import('./../components/user/OffboardedUsers.vue'),
        props: true,
        beforeEnter: authGuard,
    },
    {
        path: '/:companyID/users/billable',
        name: 'BillableUsers',
        component: () => import('../components/user/BillableUsers.vue'),
        props: true,
        beforeEnter: authGuard,
    },
    {
        path: '/:companyID/users/contractors',
        name: 'ContractorsUsers',
        component: () => import('../components/user/ContractorsUsers.vue'),
        props: true,
        beforeEnter: authGuard,
    },
    {
        path: '/:companyID/users/directory',
        name: 'DirectoryUsers',
        component: () => import('../components/user/DirectoryUsers.vue'),
        props: true,
        beforeEnter: authGuard,
    },
    {
        path: '/:companyID/users/review',
        name: 'ReviewUsers',
        component: () => import('../components/user/ReviewUsers.vue'),
        props: true,
        beforeEnter: authGuard,
    },
    {
        path: '/:companyID/assets',
        name: 'Assets',
        component: () => import('../components/asset/AllAssets.vue'),
        props: true,
        beforeEnter: authGuard,
    },
    {
        path: '/:companyID/assets/configure',
        name: 'ConfigureAssets',
        component: () => import('../components/asset/ConfigureAssets.vue'),
        props: true,
        beforeEnter: authGuard,
    },
    {
        path: '/:companyID/tickets/open',
        name: 'OpenTickets',
        component: () => import('../components/ticket/OpenTickets.vue'),
        props: true,
        beforeEnter: authGuard,
    },
    {
        path: '/:companyID/tickets/created',
        name: 'CreatedTickets',
        component: () => import('../components/ticket/CreatedPast30Tickets.vue'),
        props: true,
        beforeEnter: authGuard,
    },
    {
        path: '/:companyID/tickets/completed',
        name: 'ResolvedTickets',
        component: () => import('../components/ticket/ResolvedPast30Tickets.vue'),
        props: true,
        beforeEnter: authGuard,
    },
    {
        path: '/:companyID/contracts',
        name: 'ContractsTable',
        component: () => import('../components/contract/ContractsTable.vue'),
        props: true,
        beforeEnter: authGuard,
    },
    {
        path: '/:companyID/users/onboard',
        name: 'UserOnboard',
        component: () => import('../components/user/UserOnboard.vue'),
        props: true,
        beforeEnter: authGuard,
    },
    {
        path: '/:companyID/users/offboard',
        name: 'UserOffboard',
        component: () => import('../components/user/UserOffboard.vue'),
        props: true,
        beforeEnter: authGuard,
    },
    {
        path: '/:companyID/archives/records',
        name: 'ArchiveRecords',
        component: () => import('../components/archive/ArchiveRecords.vue'),
        props: true,
        beforeEnter: authGuard,
    },
    {
        path: '/:companyID/archives/jobs',
        name: 'RunningJobs',
        component: () => import('../components/archive/RunningJobs.vue'),
        props: true,
        beforeEnter: authGuard,
    },
    {
        path: '/:companyID/archives/user',
        name: 'UserArchive',
        component: () => import('../components/archive/UserArchive.vue'),
        props: true,
        beforeEnter: authGuard,
    },
    {
        path: '/:companyID/settings/alerts',
        name: 'AlertSettings',
        component: () => import('../components/setting/AlertSettings.vue'),
        props: true,
        beforeEnter: authGuard,
    },
    {
        path: '/:companyID/settings/archives',
        name: 'ArchiveSettings',
        component: () => import('../components/setting/ArchiveSettings.vue'),
        props: true,
        beforeEnter: authGuard,
    },
    {
        path: '/:catchAll(.*)', // Catch-all route using a parameter
        redirect: '/' // Redirect to home or any route you want
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;

