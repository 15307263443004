import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Import the router
import store from './store'; //Import the store
import { createAuth0 } from '@auth0/auth0-vue';



import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Import Bootstrap's JavaScript


const app = createApp(App);
app.use(router); // Use the router
app.use(store); // use the store
app.use(
    createAuth0({
        domain: process.env.VUE_APP_API_AUTH0_DOMAIN, // Your Auth0 domain
        clientId: process.env.VUE_APP_API_AUTH0_CLIENT_ID, // Your Auth0 client ID
        authorizationParams: {
            redirect_uri: `${window.location.origin}` // The URL to redirect after login
        },
        onRedirectCallback: (appState) => {
            router.push(
              appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname,
            );
          },
    })
);

app.mount('#app');